<template>
  <div>
    <div
      style="font-style: italic; color: black"
      class="font-size-16 text-left m-t-20"
    >
      Επιδείξτε τον παρακάτω QR κωδικό στον διανομέα κατά την παράδοση για
      ανέπαφη επιβεβαίωση παράδοσης (αντί υπογραφής)
    </div>
    <div>
      <vue-qr :text="voucherData._id" :size="240"></vue-qr>
    </div>
    <div class="font-size-28 font-weight-bold" style="color: black">
      # {{ voucherData.optimization.idBeforeOptim }}
    </div>
    <div class="m-t-20">
      <div class="font-size-16" style="color: black; font-style: italic">
        DB#: {{ voucherData._id }}
      </div>
      <v-btn small @click="saveToClipboard">
        <font-awesome-icon
          :icon="faCopy"
          style="font-size: 18px"
        ></font-awesome-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import { faCopy } from "@fortawesome/pro-solid-svg-icons";
export default {
  components: {
    VueQr,
  },
  props: {
    voucherData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      faCopy,
    };
  },
  methods: {
    saveToClipboard() {
      navigator.clipboard.writeText(this.voucherData._id);
      // TODO add a toast for success save to clipboard
    },
  },
};
</script>

<style></style>
